.custom-cursor {
    position: fixed;
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    pointer-events: none;
    z-index: 9999;
  }
  
  .circle {
    width: 10px; /* Adjust size as needed */
    height: 10px; /* Adjust size as needed */
    border-radius: 50%;
    background-color: #ffab03;
    position: absolute;
    transform: translate(50%, 50%);
  }
  .ring {
    width: 25px; /* Adjust size as needed */
    height: 25px; /* Adjust size as needed */
    border: 1px solid #ffab03;
    border-radius: 50%;
    position: absolute;
    top: -15%;
    left: -15%;
    transform: translate(50%, 50%);
    transition: transform 0.05s ease;
  }
  
  @media screen and (max-width: 600px) 
  {
    .custom-cursor {
      display: none;
    }
  }