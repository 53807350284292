@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

.App {
  font-family: 'Quicksand', sans-serif;
  text-align: center;
  background-color: #282c34;
  height: 100vh;
  overflow: auto;
  position: relative;
  background-image: linear-gradient( rgba(37, 36, 36, 0.9), rgba(0, 0, 1, 0.92) ),  url('./images/HomePageBG.jpg');
  background-size:cover;
  cursor: none;
}
::-webkit-scrollbar{
    display: none;
}
#section05 a {
  padding-top: 10px;
}
#section05 a span {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  border-left: 2px solid #ffab03;
  border-bottom: 2px solid #ffab03;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
#section05 a p {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  border-left: 2px solid #ffab03;
  border-bottom: 2px solid #ffab03;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}

.scroll a {
  position: relative;
  bottom: 10px;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #ffab03;
  font : normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
}
.scroll a:hover {
  opacity: .5;
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
