
.timeline-section
{

    margin-left: 10px;
    margin-bottom: 30px;

}
.timeline-items
{
    max-width: 1000px;
    margin: auto;
    display: flex; 
    flex-wrap: wrap;
    position: relative;
}

.timeline-icon
{
    height: 40px;
    width: 40px;
    margin-right: 20px;
    border-radius: 50%; 
}
.timeline-icon-work
{
    margin-top: 10px;
    height: 60px;
    width: 60px;
    margin-right: 20px;
    border-radius:50%;  
}

.timeline-dot
{
    height: 15px;
    width :15px;
    background-color: #ffab03;
    position: relative;
    left: -32.5px;
    top: 15px;
    border-radius: 50%;
}

.timeline-item
{
    margin-bottom: 40px;
    width: 80%;
    position: relative;
   
}


.timeline-content:hover{
    transition: 0.3s;
    transform: scale(1.1);
    color: white;
}
.timeline-content
{
    transition: 0.2s;
    background-color: #1e1f1e;
    padding : 10px;
    padding-bottom: 20px;
    color: grey;
    border-radius: 8px;
    text-align: left;
    box-shadow: -3px 3px 8px 0px #0f0f0f 
}

.timeline-items::before
{
    content: '';
    top:15px;
    position: absolute;
    width: 5px;
    height: 100%;
    background-color: black;
    margin-right: 20px;
    left: -28px;
}

.timeline-date
{
    position:  absolute;
    right: 10px;
    background-color: #0f0f0f;
    padding: 10px;
    border-radius: 30px;
    margin-left: 15px;
    box-shadow: -0.2px 1px 8px 0px #4a4a4a 
}

.MainTitle
{
    font-weight:'bold';
    display: block;
    align-items: left;
    font-size: 35px;
    margin-top: 25px;
}

.ProfileTitle
{
    margin-top: 15px;
    font-weight:'bold';
    display: block;
    align-items: start;
    font-size: 25px;
}
.descContent
{
    margin-bottom: 15px;
    font-size: 15px;
}


@media screen and (max-width: 600px) 
{

    .timeline-icon-work
    {
        margin-top: 10px;
        height: 40px;
        width: 40px;
        margin-right: 5%;
        border-radius:50%;  
    }
    .timeline-dot
    {
        height: 10px;
        width :10px;
        background-color: #ffab03;
        position: relative;
        left: -11.5%;
        top: 15px;
        border-radius: 50%;
    }
    .timeline-items
    {
        margin-left: 10%;
        max-width: 1000px;
        display: flex; 
        flex-wrap: wrap;
        position: relative;
    }

    .timeline-content
    {
        width: 100%;
        transition: 0.2s;
        background-color: #1e1f1e;
        padding : 10px;
        padding-bottom: 20px;
        color: grey;
        border-radius: 8px;
        text-align: left;
        box-shadow: -3px 3px 8px 0px #0f0f0f 
    }
    .timeline-date
    {

        font-size: 12px;
        position:  absolute;
        right: 5%;
        background-color: #0f0f0f;
        padding: 5px;
        border-radius: 30px;
        margin-left: 15px;
        margin-top: 2%;
        box-shadow: -0.2px 1px 5px 0px #4a4a4a 
    }

    .MainTitle
    {
        display: block;
        align-items: start;
        font-size: 20px;
        margin-top: 15px;
    }

    .ProfileTitle
    {
        display: block;
        align-items: start;
        font-size: 16px;
    }

    .timeline-items::before
    {
        content: '';
        top:15px;
        position: absolute;
        width: 1%;
        height: 100%;
        background-color: black;
        margin-right: 20px;
        left: -8.2%;
    }
    .timeline-content:hover .descContent{
        transition: 2s;
        display:inline;
    }
    .timeline-content:hover{
        transform: scale(1);
        color: grey;
    }
}