@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');


.father {
	width:800px;
	height:550px;
	margin:100px 10px;
	position:relative;
	transform-style: preserve-3d;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px   rgba(0,0,0,0.22);
    transition:all .6s ease-in-out;
    border-radius:11px;	
}

.father > div {
	position:absolute;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	font-size:30px;
	line-height: 1.7;
	text-align: center;
}

.father  .front {
	
	z-index:2;
	backface-visibility: hidden;
	border-radius:11px;
	color:#fff;			
}

.father .back {
	transform: rotateY(180deg);
	z-index:1;	
	backface-visibility: hidden;
	border-radius:11px;
	color:#fff;
	text-align:center;
  
}

.father.rotate {
    transform: perspective(500px) rotateY(180deg);
  }

  .social-icon{
    height: 80px;
     width: 80px 
  }

  @media screen and (max-width: 800px) 
  {
    .social-icon{
        height: 50px;
        width: 50px 
}    
.father {
	width:100%;
	margin-top:20%;
	position:relative;
	transform-style: preserve-3d;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px   rgba(0,0,0,0.22);
    transition:all .6s ease-in-out;
    border-radius:11px;	
}

}