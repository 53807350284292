@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');



.TypingTextContainer {
    font-size: 25px;
    color: 'white';
    flex-wrap: wrap;
    font-family: 'Courier, monospace'
}

.typingTextCode {
    color: #ffab03;
}

.typingText {
    display: block;
    text-align: left;
    color: white;
    width: 100%;
    font-size: 25px;
    font-family: 'Courier, monospace';
}



.rightBar {
    width: 250px;
    height: 98%;
    position: fixed;
    right: 0px;
    /* Start offscreen */
    transition: ease-in-out;
    transition-duration: 1.2s;
    background-color: #1e1f1e;
    /* Set your sidebar background color */
    box-shadow: -3px 3px 8px 0px #0f0f0f;
    z-index: 2;
    transition-duration: 0.2s;
    border-radius: 10px,

}

.rightBarClosed {
    width:80px;
    height: 98%;
    position: fixed;
    right: 0px;
    transition: ease-in-out;
    transition-duration: 1.2s;
    background-color: #1e1f1e;
    box-shadow: -3px 3px 8px 0px #0f0f0f;
    z-index: 2;
    transition-duration: 0.2s;
    border-radius: 10px,
}


@keyframes transitionIn {
    from {
        opacity: 0;
        transform: rotateY(-10deg);

    }

    to {
        opacity: 1;
        transform: rotateY(0);
    }
}




.rbarButtons {
    padding: 10px;
    color: gray;
}




.footer {
    display: flex;
    margin: 20px auto;
    width: 90%;
    height: 80px;
    border-radius: 5px;
    background-color: #1e1f1e;
}

/* -------------------------------Mobile Device-------------------------------------------- */

@media screen and (max-width: 600px) {


    .rightBar {
        width: 150px;
        position: fixed;
        right: 0px;
        /* Start offscreen */
        transition: ease-in-out;
        transition-duration: 1.2s;
        background-color: #1e1f1e;
        /* Set your sidebar background color */
        box-shadow: -3px 3px 8px 0px #0f0f0f;
        z-index: 2;
        transition-duration: 0.2s;
        border-radius: 10px,
    }

    .rightBarClosed {
        width: 10%;
        height: 100%;
        position: fixed;
        right: 0px;
        /* Start offscreen */
        transition: ease-in-out;
        transition-duration: 1.2s;
        background-color: #1e1f1e;
        /* Set your sidebar background color */
        box-shadow: -3px 3px 8px 0px #0f0f0f;
        z-index: 2;
        transition-duration: 0.2s;
        border-radius: 10px,
    }

    .TypingTextContainer {
        font-size: 15px;
        color: 'white';
        flex-wrap: wrap;
        font-family: 'Courier, monospace'
    }

    .typingTextCode {
        color: #ffab03;
    }

    .typingText {
        width: 100%;
        text-align: left;
        color: white;
        font-size: 15px;
        font-family: 'Courier, monospace';
    }

    .ContainerTitle
    {
        display: block;
        align-items: start;
        font-size:25px;
    }

    .HeadText
    {
        margin-bottom: -35px;
        margin-top:15px; 
        margin-left:15px;
        color:white
    }

    .footer {
        display: flex;
        margin-top: 30px;
        margin-bottom: 15%;
        width: 100%;
        border-radius: 5px;
        background-color: #1e1f1e;
    }
    .historyComp {
        text-align: left;
        margin-left: 2%;
        margin-top: 2%;
        margin-bottom: 10%;
        width: 100%;
        overflow: auto;
        animation: transitionIn 0.75s;
    }
}